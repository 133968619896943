import React from 'react'
import sportsCard1 from "../../../Asset/Membership/MembershipOffering/sports-card-1.svg"
import sportsCard2 from "../../../Asset/Membership/MembershipOffering/sports-card-2.svg"
import sportsCard3 from "../../../Asset/Membership/MembershipOffering/sports-card-3.svg"
import sportsCard4 from "../../../Asset/Membership/MembershipOffering/sports-card-4.svg"
import sportsCard5 from "../../../Asset/Membership/MembershipOffering/sports-card-5.svg"
import sportsCard6 from "../../../Asset/Membership/MembershipOffering/sports-card-6.svg"
import sportsCard7 from "../../../Asset/Membership/MembershipOffering/sports-card-7.svg"
import sportsCard8 from "../../../Asset/Membership/MembershipOffering/sports-card-8.svg"
import sportsCard9 from "../../../Asset/Membership/MembershipOffering/sports-card-9.svg"

const MembershipOffering = () => {
  return (
    <div id='membership-offering-container' className='mt-14 w-[94%] mx-auto'>
        <div id='membership-offering-top-heading' className='flex flex-col md:flex-row text-[var(--dark-blue)] font-medium items-center'>
            <div className='md:w-1/2 font-ClashGrotesk text-[2.3rem] md:text-[3.5rem] leading-[2.85rem] md:leading-[3.85rem]'>This is what you’ll get with the Net Gala Membership</div>
            <div className='md:w-1/2 font-manrope text-base md:text-xl mt-8 md:mt-0 pr-8'>Elevate your fitness and community engagement with a Net Gala Membership, tailored to enhance your active lifestyle with a suite of exclusive benefits. Here’s how you can use your credits to create a routine that works for you.</div>
        </div>

        <div id="membership-offering-list-grid" className='md:grid grid-cols-3 place-items-center mt-10 gap-5 hidden '>

            <div className='w-full '><img className='w-full' src={sportsCard1} alt="" /></div>
            <div className='w-full '><img className='w-full' src={sportsCard2} alt="" /></div>
            <div className='w-full '><img className='w-full' src={sportsCard3} alt="" /></div>
            <div className='w-full '><img className='w-full' src={sportsCard4} alt="" /></div>
            <div className='w-full '><img className='w-full' src={sportsCard5} alt="" /></div>
            <div className='w-full '><img className='w-full' src={sportsCard6} alt="" /></div>
            <div className='w-full '><img className='w-full' src={sportsCard7} alt="" /></div>
            <div className='w-full '><img className='w-full' src={sportsCard8} alt="" /></div>
            <div className='w-full '><img className='w-full' src={sportsCard9} alt="" /></div>            
        </div>


        <div id="membership-offering-list-mobile" className='md:hidden flex'>

           {/* For Mobile view only */}
        <div className=" md:hidden carousel mt-8  w-[100vw]  md:mb-16 flex  gap-3 md:px-0">
          <div className="carousel-item  w-[80vw] md:w-[60vw] pl-3 flex-col gap-3">
            
          <div className='w-full '><img className='w-full' src={sportsCard1} alt="" /></div>
            <div className='w-full '><img className='w-full' src={sportsCard2} alt="" /></div>
            <div className='w-full '><img className='w-full' src={sportsCard3} alt="" /></div>

          </div>
          <div className="carousel-item  w-[80vw] md:w-[60vw]  flex-col gap-3">
           
          <div className='w-full '><img className='w-full' src={sportsCard4} alt="" /></div>
            <div className='w-full '><img className='w-full' src={sportsCard5} alt="" /></div>
            <div className='w-full '><img className='w-full' src={sportsCard6} alt="" /></div>

          </div>
          <div className="carousel-item  w-[80vw] md:w-[60vw] pr-3 flex-col gap-3">
            
          <div className='w-full '><img className='w-full' src={sportsCard7} alt="" /></div>
            <div className='w-full '><img className='w-full' src={sportsCard8} alt="" /></div>
            <div className='w-full '><img className='w-full' src={sportsCard9} alt="" /></div> 

          </div>
        </div>

        </div>



    </div>
  )
}

export default MembershipOffering
