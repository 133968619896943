import React, { useState, useEffect } from 'react'


//importing the badge stamp images
import BadgeImage from "../../Components/Badges"
import calendarVector from "../../../Asset/Events/calendar-vector-white.svg"
import timeVector from "../../../Asset/Events/time-vector-white.svg"
import locationVector from "../../../Asset/Events/location-vector-white.svg"
import coinVector from "../../../Asset/Events/coin-vector-white.svg"
import { Link } from 'react-router-dom'
import { format } from "date-fns";
import Axios from "axios";
import rightArrowVector from "../../../Asset/right-arrow-vector.svg"
import { ClipLoader } from 'react-spinners'

const UpcomingEvents = () => {

    const [shownSpotlightEventData, setShownSpotlightEventData] = useState();
    const [loading, setLoading] = useState(true); // Track loading state
    const [error, setError] = useState(null); // Track error state
    const [formattedDates, setFormattedDates] = useState({});
    const [imageUrl, setImageUrl] = useState("");
    const [imageUrlWide, setImageUrlWide] = useState("");
    const BadgeSize = { width: "100%", height: "100%" };
    const [spotlightEvents, setSpotlightEvents] = useState([]);

    useEffect(() => {
        const fetchData = async () => {

            try {
                const res = await Axios.get(`${process.env.REACT_APP_BASE_URL}/events/`);
                const allEvents = res.data.events
                console.log("view here")
                console.log(res.data.events)

                setShownSpotlightEventData([])
                if (allEvents?.length > 0) {

                    console.log("entering here")
                    //Finding SpotlightImage
                    const filterSpotlightEvents = res.data.events.filter(
                        (event) => Array.isArray(event.EventTags) && event.EventTags.some(tag => tag.toUpperCase() === "SPOTLIGHT")
                    );
                    setSpotlightEvents(filterSpotlightEvents)


                    let data = filterSpotlightEvents[0]

                    const today = new Date();

                    // Filter events further by RegistrationEndDate >= today's date
                    const upcomingEvents = filterSpotlightEvents.filter(event =>
                        new Date(event.event_schedule.RegistrationEndDate) >= today
                    );

                    console.log("Upcoming Events", upcomingEvents)


                    // Find the event with the nearest RegistrationEndDate
                    const nearestEvent = upcomingEvents?.length > 0
                        ? upcomingEvents.reduce((nearest, current) => {
                            const nearestDate = new Date(nearest.event_schedule.RegistrationEndDate);
                            const currentDate = new Date(current.event_schedule.RegistrationEndDate);
                            return currentDate <= nearestDate ? current : nearest;
                        }, upcomingEvents[0])
                        : null;

                    console.log("nearest event", nearestEvent)

                    setShownSpotlightEventData(nearestEvent);



                    // Extract and format all relevant dates
                    if (nearestEvent.event_schedule) {
                        const startDate = format(new Date(nearestEvent.event_schedule.StartTimeStamp), "do MMM, yyyy");
                        const endDate = format(new Date(nearestEvent.event_schedule.EndTimeStamp), "do MMM, yyyy");
                        const registrationStartDate = format(new Date(nearestEvent.event_schedule.RegistrationStartTimeStamp), "dd MMM, EEEE yyyy");
                        const registrationEndDate = format(new Date(nearestEvent.event_schedule.RegistrationEndTimeStamp), "dd MMM, EEEE yyyy");
                        const StartTimeStamp = format(new Date(nearestEvent.event_schedule.StartTimeStamp), "hh:mm a");
                        const EndTimeStamp = format(new Date(nearestEvent.event_schedule.EndTimeStamp), "hh:mm a");

                        // Store formatted dates in state
                        setFormattedDates({
                            startDate,
                            endDate,
                            registrationStartDate,
                            registrationEndDate,
                            StartTimeStamp,
                            EndTimeStamp
                        });
                    }

                    console.log("start date is: ", formattedDates.startDate)

                    // Fetch images directly after fetching event data
                    const spotlightImage = nearestEvent.EventImages.find((image) =>
                        image.Tags.includes("SPOTLIGHT")
                    );
                    console.log('spotlight image', spotlightImage)
                    const spotlightImageWide = nearestEvent.EventImages.find((image) =>
                        image.Tags.includes("WIDE")
                    );
                    console.log('spotlight image wide', spotlightImageWide)
                    console.log("coming here")
                    // if (!spotlightImage || !spotlightImageWide) return;

                    console.log("coming here")
                    // Fetch spotlight image
                    if (spotlightImage) {
                        const spotlightResponse = await Axios.get(
                            `${process.env.REACT_APP_BASE_URL}/files/${spotlightImage.FileId}/download`,
                            {
                                responseType: "blob",
                            }
                        );
                        setImageUrl(URL.createObjectURL(spotlightResponse.data));
                    }

                    if (spotlightImageWide) {
                        // Fetch wide spotlight image
                        const wideResponse = await Axios.get(
                            `${process.env.REACT_APP_BASE_URL}/files/${spotlightImageWide.FileId}/download`,
                            {
                                responseType: "blob",
                            }
                        );
                        setImageUrlWide(URL.createObjectURL(wideResponse.data));
                    }                   
                    setLoading(false); // Stop loading
                }

            } catch (error) {
                console.error("Error fetching data or images:", error);
                setImageUrl("");
                setImageUrlWide("");
                setLoading(false);


            } finally {
                setLoading(false)
            }
        };

        fetchData();
    }, []);





    return (
        <>

            <div className='w-full flex flex-col gap-2 mt-10'>
                {/* All Upcoming Events Text Here */}
                <div className='flex justify-center md:justify-end'>

                    <Link to={`/events-listing`}>
                        <span className='flex gap-2 text-base md:text-2xl  font-manrope font-semibold text-[var(--white)] opacity-[50%] hover:opacity-70 cursor-pointer'>All Upcoming Events <img src={rightArrowVector} className='w-4 md:w-7' alt="right arrow vector" /></span>
                    </Link>

                </div>
                {console.log("shownspotlight event data, ", shownSpotlightEventData)}
                {!loading ?
                    (shownSpotlightEventData ? (

                        <div className="homeAboutUs-event-card mb-12 flex flex-col md:flex-row justify-between items-center w-[95%] md:w-full p-2 py-4 rounded-3xl shadow-xl mx-auto md:p-4 md:pb-6">
                            {/* Inside container left part */}
                            <div className="flex flex-col md:flex-row xl:w-[57%] md:w-[60%] md:h-[95%] w-full items-center p-3 md:gap-4 lg:gap-6 md:p-6 relative">
                                {/* Image here */}
                                <div className="md:hidden  w-full h-[13rem] ">
                                    {imageUrlWide ? (
                                        <img className='w-full h-full rounded-3xl' src={imageUrlWide} alt={shownSpotlightEventData.EventTitle} />
                                    ) : ""}
                                </div>
                                <div className="hidden md:block w-[32rem] h-[22rem]">
                                    {imageUrl ? (
                                        <img className='w-full h-full rounded-3xl' src={imageUrl} alt={shownSpotlightEventData.EventTitle} />
                                    ) : ""}
                                </div>

                                {/* Text part */}
                                <div className="flex flex-col w-full justify-start md:h-full md:mb-auto md:gap-2 md:w-full text-[var(--white)] md:pl-6 relative ">
                                    <div className="flex">
                                        {/* Heading */}
                                        <div className="w-[78%] md:w-full">
                                            <h1 className="xl:text-5xl lg:text-3xl md:text-2xl text-[1.75rem] font-[470] py-4 md:py-2 text-left font-ClashGrotesk md:mb-2">
                                                {shownSpotlightEventData.EventTitle}
                                            </h1>
                                        </div>

                                        {/* Badge image for Mobile view */}
                                        <div className="md:hidden w-[40%] ml-0  absolute right-[-2rem] top-[-2rem]">
                                            <BadgeImage EventType={shownSpotlightEventData.EventType} size={BadgeSize} />
                                        </div>
                                    </div>

                                    {/* Event Description */}
                                    <p id='scroll-container' className="pb-3 pt-4 md:pt-0 text-left font-manrope lg:text-xl text-sm max-h-28 md:max-h-36 overflow-y-auto">
                                        {shownSpotlightEventData.EventDescription}
                                    </p>
                                    <hr className="border border-[var(--medium-purple)] border-solid md:mb-2 mb-4 md:w-[95%] my-3 md:my-0" />

                                    {/* Event details */}
                                    <div className="flex pb-3 md:pb-2  gap-2 w-full font-manrope text-sm lg:text-base ">
                                        <span className="flex justify-start items-start gap-2 w-full">
                                            <span className='flex-shrink-0'><img src={calendarVector} alt="calendar" className='w-[1.1rem]' /></span>
                                            {formattedDates.startDate}
                                        </span>
                                        <span className="flex text-left items-start gap-2 w-full">
                                            <span className='flex-shrink-0'><img src={timeVector} alt="time" className=' ' /> </span>
                                            <span className="flex-wrap">
                                                <div className="inline-block">{formattedDates.StartTimeStamp}</div>
                                                <div className="inline-block">-</div>
                                                <div className="inline-block">{formattedDates.EndTimeStamp}</div>

                                            </span>
                                        </span>
                                    </div>
                                    <div className="flex pb-2 gap-2 w-full font-manrope text-sm lg:text-base">
                                        <span className="flex justify-start items-start gap-2 w-full">
                                            <span className='flex-shrink-0'><img src={locationVector} alt="location" /></span>
                                            <span> {shownSpotlightEventData?.venue?.VenueAddress?.city}</span>
                                        </span>
                                        <span className="flex justify-start items-start gap-2 w-full">
                                            <span><img src={coinVector} alt="price" /></span>
                                            ₹{shownSpotlightEventData.Price}
                                        </span>
                                    </div>
                                    <hr className="border border-[var(--medium-purple)] border-solid md:mt-2 mt-4 md:w-[95%] my-3 md:my-0" />
                                </div>
                            </div>

                            {/* Inside container right part */}
                            <div className="flex flex-col md:justify-between md:items-end  w-full md:w-[30%] md:h-[100%] gap-12  ">
                                <div className='max-w-[18rem] -mt-6  hidden md:block'>
                                    <BadgeImage EventType={shownSpotlightEventData.EventType} size={BadgeSize} className="hidden md:block w-full h-full" />
                                </div>

                                <Link to={`/events/${shownSpotlightEventData.EventId}`} className='mr-12 md:mr-10 w-full flex justify-center pb-2 md:pb-0'>
                                    <button className="flex justify-center border border-solid bg-[var(--button-green)] border-[var(--medium-purple)] text-[var(--purple-blue)] text-lg lg:text-xl xl:text-2xl md:py-5 md:w-full py-3 rounded-xl  font-manrope font-bold md:-mt-8 w-[94%] mb-2 md:mb-0">
                                        Register
                                    </button>
                                </Link>
                            </div>
                        </div>
                    ) : (''))
                    : (<div className='w-full flex justify-center items-center'>
                        <ClipLoader color="white" size={30} />
                    </div>)}
            </div>

        </>
    );
};

export default UpcomingEvents;
