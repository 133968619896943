import React from 'react'
import pickleballPlayPass from "../../../Asset/Membership/MembershipSchedule/pickleball-play-pass.svg"
import footballPlayPass from "../../../Asset/Membership/MembershipSchedule/football-play-pass.svg"
import pickleballCocahing from "../../../Asset/Membership/MembershipSchedule/pickleball-coaching.svg"
import fitnessBootcamp from "../../../Asset/Membership/MembershipSchedule/fitness-bootcamp.svg"
import footballCheckbox from "../../../Asset/Membership/MembershipSchedule/football-checkbox.svg"
import padelCheckbox from "../../../Asset/Membership/MembershipSchedule/padel-checkbox.svg"
import pickleballCheckbox from "../../../Asset/Membership/MembershipSchedule/pickleball-checkbox.svg"
import bronzeCheckbox from "../../../Asset/Membership/MembershipSchedule/bronze-checkbox.svg"
import silverCheckbox from "../../../Asset/Membership/MembershipSchedule/silver-checkbox.svg"
import goldCheckbox from "../../../Asset/Membership/MembershipSchedule/gold-checkbox.svg"

const MembershipScheduleSection = () => {
    return (
        <div id='membership-schedule-section' className='my-24 w-[94%] mx-auto'>
            <div id="membership-schedule-section-container">
                {/* Top heading */}
                <div className='font-ClashGrotesk text-[2.3rem] md:text-[3.5rem] leading-[2.85rem] md:leading-[3.85rem] text-[var(--dark-blue)] font-[520]'>
                    Let’s build your sample Net Gala schedule!
                </div>

                {/* white box container */}

                <div id="membership-schedule-white-box-container" className='bg-[var(--white)] flex px-10 py-[4.5rem]  mt-10 rounded-3xl'>

                    {/* left side portion */}
                    <div id="membership-schedule-left-side" className='w-[43%] flex flex-col justify-between items-start font-ClashGrotesk text-[var(--dark-blue)] text-[2rem] font-[530]' >

                        {/* top text */}
                        <div>
                            I want to play
                        </div>

                        {/* sports checkbox image here */}
                        <div id="membership-schedule-sport-checkbox" className='flex gap-4 -mt-5 mb-2'>
                            <div>
                                <img src={footballCheckbox} alt="football checkbox" />
                            </div>
                            <div>
                                <img src={padelCheckbox} alt="padel checkbox" />
                            </div>
                            <div>
                                <img src={pickleballCheckbox} alt="pickleball checkbox" />
                            </div>
                        </div>

                        <div>
                            with this tier membership
                        </div>


                        {/* package checkbox image here */}
                        <div id="membership-schedule-sport-checkbox" className='flex gap-3 -mt-5'>
                            <div>
                                <img src={bronzeCheckbox} alt="bronze chekcbox" />
                            </div>
                            <div>
                                <img src={silverCheckbox} alt="silver checkbox" />
                            </div>
                            <div>
                                <img src={goldCheckbox} alt="gold checkbox" />
                            </div>
                        </div>


                        {/* bottom button  */}
                        <button className='bg-[var(--button-green)] text-[var(--home-about-us-ptn-button-grey)] font-manrope font-semibold text-[1.077rem] py-4 w-[65%] rounded-xl'>
                            See Offerings
                        </button>

                    </div>

                    {/* right side portion */}
                    <div id="membership-schedule-right-side" className='w-[57%] flex flex-col gap-4 items-end'>
                        <div>
                            <img src={pickleballPlayPass} alt="pickleball play pass" />
                        </div>
                        <div>
                            <img src={footballPlayPass} alt="football play pass" />
                        </div>
                        <div>
                            <img src={pickleballCocahing} alt="pickleball coaching" />
                        </div>
                        <div>
                            <img src={fitnessBootcamp} alt="fitness bootcamp" />
                        </div>
                    </div>

                </div>

            </div>
        </div>
    )
}

export default MembershipScheduleSection
