import React, { useState, useEffect } from 'react';
import filterBtn from "../../../Asset/EventListing/filter-button.svg";
import EventsFilterModal from './EventsFilterModal';

const FilterButton = ({ count, allFilters, filters, setFilters}) => {
    const [isModalOpen, setIsModalOpen] = useState(false);

    // Function to toggle modal visibility
    const toggleModal = () => {
        setIsModalOpen(!isModalOpen);
    };

    // State for selected filters
    const allRegions = Array.isArray(allFilters.regions) ? [...allFilters.regions] : [];
    const allSportsType = Array.isArray(allFilters.sports) ? [...allFilters.sports] : [];
    const allEventType = Array.isArray(allFilters.events) ? [...allFilters.events] : [];
    const [selectedCities, setSelectedCities] = useState(filters.regions);
    const [selectedSports, setSelectedSports] = useState(filters.sports);
    const [selectedEvents, setSelectedEvents] = useState(filters.events);

    // Toggle function for cities, sports, and events
    const handleToggle = (filterType, id, name) => {
        if (filterType === 'city') {
            setSelectedCities((prevSelected) =>
                prevSelected.some(cityObj => cityObj.id === id)
                    ? prevSelected.filter(cityObj => cityObj.id !== id)
                    : [...prevSelected, {id:id, name:name}]
            );
        } else if (filterType === 'sport') {
            setSelectedSports((prevSelected) =>
                prevSelected.some(sportObj => sportObj.id === id)
                    ? prevSelected.filter(sportObj => sportObj.id !== id)
                    : [...prevSelected, {id:id, name:name}]
            );
        } else if (filterType === 'event') {
            setSelectedEvents((prevSelected) =>
                prevSelected.some(eventObj => eventObj.id === id)
                    ? prevSelected.filter(eventObj => eventObj.id !== id)
                    : [...prevSelected, {id:id, name:name}]
            );
        }
    };

    useEffect(() => {
        setFilters({
            "regions": selectedCities,
            "sports": selectedSports,
            "events": selectedEvents
        })
    }, [selectedCities, selectedSports, selectedEvents]); 

    return (
        <div>
            {/* Modal component - Only render if isModalOpen is true */}
            {isModalOpen && (
                <EventsFilterModal
                    allRegions={allRegions}
                    allSportsType={allSportsType}
                    allEventType={allEventType}
                    selectedCities={selectedCities}
                    selectedSports={selectedSports}
                    selectedEvents={selectedEvents}
                    onClose={toggleModal}  // Close modal function
                />
            )}

            {/* Filter button for web view, hidden in mobile */}
            <div className='w-full md:flex justify-center hidden pt-20'>
                <div className="flex md:w-[92%] justify-start gap-12">
                    {/* Cities Filter */}
                    <div className="flex flex-col ">
                        <h3 className=" mb-2 font-manrope font-[630] text-xs text-[var(--eventListing-filterbtn-heading-gray)]">City</h3>
                        <div className="flex flex-wrap gap-3">
                            {allRegions.map((city) => (
                                <button
                                    key={city.id}
                                    onClick={() => handleToggle('city', city.id, city.name)}
                                    className={`px-3 py-2 rounded-md text-base font-manrope font-[630] text-[var(--filter-button-text-purple)] border border-[var(--eventListing-filterbtn-border-faded-purple)] transition-colors flex ${selectedCities.some(cityObj => cityObj.id === city.id)
                                        ? 'bg-[var(--filter-button-background-purple)]'
                                        : 'bg-[var(--eventListing-filterbtn-bg-white)]'
                                        }`}
                                >
                                    {city.name}
                                    {selectedCities.some(cityObj => cityObj.id === city.id) && (
                                        <span
                                            className="ml-2 text-[#5C1BDC87] text-base font-bold cursor-pointer border-[0.5px] border-solid border-[#5C1BDC87] rounded-full w-5 h-5 flex flex-row items-center justify-center"
                                            onClick={(e) => {
                                                e.stopPropagation();
                                                handleToggle('city', city.id, city.name);
                                            }}
                                        >
                                            <span className='text-[0.6rem]'>&#10006;</span>
                                        </span>
                                    )}
                                </button>
                            ))}
                        </div>
                    </div>

                    {/* Sports Filter */}
                    <div className="flex flex-col ">
                        <h3 className="mb-2 font-manrope font-[630] text-xs text-[var(--eventListing-filterbtn-heading-gray)]">Sports</h3>
                        <div className="flex flex-wrap gap-3">
                            {allSportsType.map((sport) => (
                                <button
                                    key={sport.id}
                                    onClick={() => handleToggle('sport', sport.id, sport.name)}
                                    className={`px-3 py-2 rounded-md text-base font-manrope font-[630] text-[var(--filter-button-text-purple)] border border-[var(--eventListing-filterbtn-border-faded-purple)] transition-colors flex ${selectedSports.some(sportObj => sportObj.id === sport.id)
                                        ? 'bg-[var(--filter-button-background-purple)]'
                                        : 'bg-[var(--eventListing-filterbtn-bg-white)]'
                                        }`}
                                >
                                    {sport.name}
                                    {selectedSports.some(sportObj => sportObj.id === sport.id) && (
                                        <span
                                            className="ml-2 text-[#5C1BDC87] text-base font-bold cursor-pointer border border-solid border-[#5C1BDC87] rounded-full w-5 h-5 flex items-center justify-center"
                                            onClick={(e) => {
                                                e.stopPropagation();
                                                handleToggle('sport', sport.id, sport.name);
                                            }}
                                        >
                                            <span className='text-[0.6rem]'>&#10006;</span>
                                        </span>
                                    )}
                                </button>
                            ))}
                        </div>
                    </div>

                    {/* Events Filter */}
                    <div className="flex flex-col">
                        <h3 className="mb-2 font-manrope font-[630] text-xs text-[var(--eventListing-filterbtn-heading-gray)]">Events</h3>
                        <div className="flex flex-wrap gap-3">
                            {allEventType.map((event) => (
                                <button
                                    key={event.id}
                                    onClick={() => handleToggle('event', event.id, event.name)}
                                    className={`px-3 py-2 rounded-md text-base font-manrope font-[630] text-[var(--filter-button-text-purple)] border border-[var(--eventListing-filterbtn-border-faded-purple)] transition-colors flex ${selectedEvents.some(eventObj => eventObj.id === event.id)
                                        ? 'bg-[var(--filter-button-background-purple)]'
                                        : 'bg-[var(--eventListing-filterbtn-bg-white)]'
                                        }`}
                                >
                                    {event.name}
                                    {selectedEvents.some(eventObj => eventObj.id === event.id) && (
                                        <span
                                            className="ml-2 text-[#5C1BDC87] text-base font-bold cursor-pointer border border-solid border-[#5C1BDC87] rounded-full w-5 h-5 flex items-center justify-center"
                                            onClick={(e) => {
                                                e.stopPropagation();
                                                handleToggle('event', event.id, event.name);
                                            }}
                                        >
                                            <span className='text-[0.6rem]'>&#10006;</span>
                                        </span>
                                    )}
                                </button>
                            ))}
                        </div>
                    </div>
                </div>
            </div>

            <div className='w-full flex justify-center pt-16 md:pt-24 md:pb-4'>
                <div id="eventListing-Count" className='w-[93%] md:w-[92%] pb-8 flex justify-between items-center'>
                    <div className='text-[var(--dark-blue)] font-ClashGrotesk text-2xl md:text-[2rem] font-medium'>
                        {count} Upcoming Events
                    </div>

                    {/* filter button for mobile view, will be hidden in big screens */}
                    <div className="md:hidden">
                        <button onClick={toggleModal}>
                            <img src={filterBtn} alt="" />
                        </button>
                    </div>
                </div>
            </div>

            {/* EventsFilterModal */}
            <EventsFilterModal
                isOpen={isModalOpen}
                onClose={toggleModal}
                selectedCities={selectedCities}
                selectedSports={selectedSports}
                selectedEvents={selectedEvents}
                handleToggle={handleToggle}
                allRegions={allRegions}
                allSportsType={allSportsType}
                allEventType={allEventType}
            />
        </div>
    );
};

export default FilterButton;
