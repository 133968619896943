import React, { useEffect, useState } from "react";
import "./currentEvent.css";
import BadgeImage from "../../Components/Badges"
import minusVector from "../../../Asset/Events/minus-vector.svg";
import plusVector from "../../../Asset/Events/plus-vector.svg";
import calendarVector from "../../../Asset/Events/calendar-vector.svg";
import locationVector from "../../../Asset/Events/location-vector.svg";
import timeVector from "../../../Asset/Events/time-vector.svg";
import linkVector from "../../../Asset/Events/link-vector.svg";
import partnerVector from "../../../Asset/Events/partner-vector.svg"
import CheckoutModal from "./CheckoutModal";
import AccordianFaq from "../EventComponents/AccordianFaq";
import ShowSponsors from "../EventComponents/ShowSponsors";
import MoreEvents from "./moreEvents";
import PaymentSuccessBackgroundImage from "../../../Asset/Events/paymentStatus/payment-success-banner.png"
import DownloadInvoiceButton from "../../../Asset/Events/paymentStatus/Download-invoice-button.svg"
import RetryPaymentButton from "../../../Asset/Events/paymentStatus/Retry-payment-button.svg"
import RedCrossPaymentFailure from "../../../Asset/Events/paymentStatus/red-cross-payment-failure.svg"
import Axios from "axios";
import { format } from "date-fns";
import { useParams, useSearchParams } from 'react-router-dom';
import { ClipLoader } from 'react-spinners';
import { Link } from "react-router-dom";



const CurrentEvent = () => {
  // Initialize state to store event data
  const [eventData, setEventData] = useState([]);
  const [loading, setLoading] = useState(true); // Track loading state
  const [error, setError] = useState(null); // Track error state
  const [formattedDates, setFormattedDates] = useState({});
  const [isPartnerSel, setisPartnerSel] = useState(false);
  let { eventId } = useParams();
  const [count, setCount] = useState(1);
  // track have-partner in payment card
  const [havePartnerOption, sethavePartnerOption] = useState("");
  const [havePartnerFlag, setHavePartnerFlag] = useState(false);
  const [moreEvents, setMoreEvent] = useState(null);
  const [imageUrlSquare, setImageUrlSquare] = useState("");
  const [imageUrlWide, setImageUrlWide] = useState("");
  const [checkbox1Checked, setCheckbox1Checked] = useState(false); // State for the first checkbox
  const [checkbox2Checked, setCheckbox2Checked] = useState(false); // State for the second checkbox

  // Use States for payment status view
  const [topBar, setTopBar] = useState(true);
  const [paymentSuccessView, setPaymentSuccessView] = useState(false);
  const [paymentFailureView, setPaymentFailureView] = useState(false);
  // Use State for waitlist management
  const [addToWaitlist, setAddToWaitlist] = useState(false);
  const [alreadyOnWaitlist, setAlreadyOnWaitlist] = useState(false);

  // Use States for payment status
  const [paymentData, setPaymentData] = useState(null); // State to store payment data
  const [storeSubOrderId, setStoreSubOrderId] = useState('');
  const [loadingStatus, setLoadingStatus] = useState(true); // State to handle loading
  const [errorStatus, setErrorStatus] = useState(null); // State to handle errors

  const [invoiceBtnLoading, setInvoiceBtnLoading] = useState(false);

  //use States for retry button
  const [loadingRetryButton, setLoadingRetryButton] = useState(false);

  const handleAddToWaitlist = (data) => {
    setAddToWaitlist(data);
  }

  const handleAlreadyOnWaitlist = (data) => {
    setAlreadyOnWaitlist(data);
  }


  const [qParams] = useSearchParams();
  const payment_id = qParams.get('payment_id');


  // Fetch Payment Status and set payment status success and failure view
  useEffect(() => {
    if (!payment_id) {
      setErrorStatus('Payment ID is missing');
      setLoadingStatus(false);
      return;
    }

    const fetchPaymentStatus = async () => {
      try {
        setLoadingStatus(true);
        const res = await Axios.get(
          `${process.env.REACT_APP_BASE_URL}/payments/status/?payment_id=${payment_id}`
        ); // Include payment_id in API request


        setPaymentData(res.data); // Store the response data
        setLoadingStatus(false);
        setStoreSubOrderId(res.data.SubOrderId)

        // Payment Status conditions
        if (res.data.TransactionStatus === 'Success') {

          setPaymentSuccessView(true);
          setPaymentFailureView(false);

        } else {

          setPaymentSuccessView(false);
          setPaymentFailureView(true);

        }

      } catch (err) {
        setErrorStatus(err.message || 'An errorStatus occurred while fetching payment status');
        setLoadingStatus(false);
      }
    };

    fetchPaymentStatus();
  }, [payment_id]);


  const today = new Date();
  const startOfDay = `${today.getFullYear()}-${(today.getMonth() + 1)
    .toString()
    .padStart(2, '0')}-${today.getDate().toString().padStart(2, '0')}T00:00:00`;


  // Fetch data from the backend when the component is mounted
  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await Axios.get(`${process.env.REACT_APP_BASE_URL}/events/?limit=100&event_time_gteq=${encodeURIComponent(startOfDay)}`);
        // const res = await Axios.get(`${process.env.REACT_APP_BASE_URL}/events/0e26fbc7-be70-4a66-bebd-f3638e503df4/`);
        //const res = await Axios.get("/event-details.json")

        const filteredData = res.data.events.filter(event => event.EventId === eventId)[0]
        const moreEvents = res.data.events.filter(event => event.EventId !== eventId)
        setMoreEvent(moreEvents);
        setEventData(filteredData); // Set event data from response

        const data = filteredData;


        // Fetching image data

        //SquareImg
        const squareImg = data.EventImages.find((image) => image.Tags.includes("SQUARE"));
        const squareImgFileId = squareImg ? squareImg.FileId : null;

        //WideImag
        const wideImg = data.EventImages.find((image) => image.Tags.includes("WIDE"));
        const wideImgFileId = wideImg ? wideImg.FileId : null;

        console.log("Square image field: ", squareImgFileId)
        console.log("Wide image field: ", wideImgFileId)

        if (!squareImgFileId && squareImgFileId === null) {
          console.error("No square image is found for the event", data.EventTitle)
        }

        if (!wideImgFileId && wideImgFileId === null) {
          console.error("No wide image is found for the event", data.EventTitle)
        }

        try {
if(squareImgFileId){
          const responseSquare = await Axios.get(
            `${process.env.REACT_APP_BASE_URL}/files/${squareImgFileId}/download`,
            {
              responseType: "blob",
            }
          );


          const imageObjectURL = URL.createObjectURL(responseSquare.data);
          setImageUrlSquare(imageObjectURL);
        }

        if(wideImgFileId){
          const responseWide = await Axios.get(`${process.env.REACT_APP_BASE_URL}/files/${wideImgFileId}/download`,
            {
              responseType: "blob",
            }
          );
          const imageObjectURLWide = URL.createObjectURL(responseWide.data);
          setImageUrlWide(imageObjectURLWide);

        }
          

        } catch (imageError) {
          console.log('Error fetching image: ', imageError)
          setImageUrlSquare("");
        }


        // Extract and format all relevant dates
        if (data.event_schedule) {
          const startDate = format(new Date(data.event_schedule.StartTimeStamp), "do MMM, EEEE, yyyy");
          const endDate = format(new Date(data.event_schedule.EndTimeStamp), "do MMM, EEEE, yyyy");
          const registrationStartDate = format(new Date(data.event_schedule.RegistrationStartTimeStamp), "dd MMM, EEEE yyyy");
          const registrationEndDate = format(new Date(data.event_schedule.RegistrationEndTimeStamp), "dd MMM, EEEE yyyy");
          const StartTimeStamp = format(new Date(data.event_schedule.StartTimeStamp), "hh:mm a");
          const EndTimeStamp = format(new Date(data.event_schedule.EndTimeStamp), "hh:mm a");


          // Store formatted dates in state
          setFormattedDates({
            startDate,
            endDate,
            registrationStartDate,
            registrationEndDate,
            StartTimeStamp,
            EndTimeStamp
          });
        }
        setLoading(false); // Stop loading

      } catch (error) {
        setError(error.message);
        console.error("Error fetching data or images:", error);
        setLoading(false);
      }
    };

    //everytime new event selected, count is reset back to 1;
    setCount(1);
    setCheckbox1Checked(false); // Uncheck checkbox 1
    setCheckbox2Checked(false); // Uncheck checkbox 2
    fetchData();
  }, [eventId]);

  // Handle download invoice
  const handleDownloadInvoice = async (e) => {
    e.preventDefault(); // Prevent the default button click behavior
    setInvoiceBtnLoading(true)
    try {
      if (!payment_id) {
        console.error('Payment ID is not available');
        return;
      }

      const response = await Axios.get(
        `${process.env.REACT_APP_BASE_URL}/payments/${payment_id}/invoice`,
        {
          params: { payment_id }, // Send the stored payment_id as a query parameter
          responseType: 'blob', // Expect a file (Blob) in the response
        }
      );

      // Create a URL for the Blob and trigger the download
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `NG-${payment_id}.pdf`); // Set the desired file name
      document.body.appendChild(link);
      link.click();
      link.remove(); // Clean up the link element
    } catch (error) {
      console.error('Error downloading the invoice:', error);
      // alert('Failed to download the invoice. Please try again.');

    }
    setInvoiceBtnLoading(false)
  };



  // Handle payment retry
  const handleRetry = async (e) => {
    e.preventDefault();

    setLoadingRetryButton(true);

    try {
      const checkoutResponse = await Axios.post(
        `${process.env.REACT_APP_BASE_URL}/checkout/?sub_order_id=${storeSubOrderId}`, // URL
        {}, // Data (empty object since no body is being sent)
        {
          headers: {
            "Content-Type": "application/json",
            accept: "application/json",
          },
        } // Config (e.g., headers)
      );

      const { RazorpayOrderId, amount } = checkoutResponse.data;
      handleRazorpayPayment(RazorpayOrderId, amount);
    } catch (error) {
      console.error("Error during checkout:", error);
      // Optional: Handle specific error cases
    } finally {
      setLoadingRetryButton(false);
    }
  }


  // Handle Razorpay checkout
  const handleRazorpayPayment = async (RazorpayOrderId, amount) => {
    amount = amount * 100 * count;
    //Dynamically create a form to submit to Razorpay Hosted Checkout
    const form = document.createElement("form");
    form.setAttribute("method", "POST");
    form.setAttribute(
      "action",
      "https://api.razorpay.com/v1/checkout/embedded"
    );
    form.setAttribute("target", "_blank");

    //Define form fields dynamically
    const fields = [
      { name: "key_id", value: "rzp_test_w402YRQwCZ0zlG" }, // Your Razorpay key ID
      { name: "order_id", value: RazorpayOrderId }, // The order ID returned from backend
      { name: "amount", value: amount * 100 }, // Amount in paise (multiply INR by 100)
      { name: "currency", value: "INR" },

      { name: "name", value: "Net Gala" },
      { name: "description", value: "Please complete your payment to proceed." },
      { name: "image", value: `${process.env.REACT_APP_HOSTED_URL}/logo.png` }, // Company logo or product image
      // { name: "image", value: `http:/localhost:3000/logo.png` }, // Company logo or product image
      // { name: "image", value: `https://ibb.co/wM7K7Kc` }, // Company logo or product image
      {
        name: "callback_url",
        value: `${process.env.REACT_APP_BASE_URL}/payment/callback/`,
      },
      {
        name: "cancel_url",
        value: `${process.env.REACT_APP_BASE_URL}/payment/cancel`,
      },
      // { name: "prefill[name]", value: "name" }, // Optional pre-fill for name
      // { name: "prefill[contact]", value: "" }, // Optional pre-fill for contact
      // { name: "prefill[email]", value: "email" }, // Optional pre-fill for email
    ];

    //Append fields to the form
    fields.forEach(({ name, value }) => {
      const input = document.createElement("input");
      input.setAttribute("type", "hidden");
      input.setAttribute("name", name);
      input.setAttribute("value", value);
      form.appendChild(input);
    });

    // Append form to the body and submit it automatically
    document.body.appendChild(form);
    form.submit();



  };



  //Defining size of badge for the current event
  const BadgeSize = { width: '18rem', height: '18rem' };

  //For checkout modal
  const [isModalOpen, setIsModalOpen] = useState(false);

  const openModal = () => setIsModalOpen(true);
  const closeModal = () => setIsModalOpen(false);

  if (error) {

    return (
      <div className="w-full h-[100vh] flex justify-center items-center">
        <p>Error loading data... {error.message}</p>

      </div>

    ) // Show error message
  }


  // Function to handle increment 
  const increment = () => {
    // Only restrict increment if partner is selected and IsPartnerAllowed is true
    if (eventData.isRegistrationOpen === true) {
      if (eventData.IsPartnerAllowed === false) {
        // setCount(prevCount => Math.min(prevCount + 1, eventData.TeamSize * eventData.TotalTeams));
        setCount(1)
      }
    }

  };

  // Function to handle decrement
  const decrement = () => {
    // Only restrict decrement if partner is selected and IsPartnerAllowed is true
    if (eventData.isRegistrationOpen === true) {
      if (eventData.IsPartnerAllowed === false) {
        // setCount(prevCount => Math.max(prevCount - 1, 1));
        setCount(1)
      }
    }

  };

  // Function to handle partner/team selection
  const handleSelect = (option) => {

    if (eventData.isRegistrationOpen === true) {
      if (option === havePartnerOption) {
        // Deselect if the same option is clicked
        sethavePartnerOption("");
        setCount(1); // Reset count to 1 on deselection
        setisPartnerSel(false); // Reset partner selection state
      } else {
        // Update partner option and count based on the selected option
        setHavePartnerFlag(option === "yes" ? true : false);
        sethavePartnerOption(option);
        if (eventData.IsPartnerAllowed) {
          if (eventData.TeamSize === 1) {
            setCount(option === "yes" ? 1 : 1); // Set count to 2 if partner, else 1
          } else {
            setCount(option === "yes" ? eventData.TeamSize : 1); // Set to max players for team, else 1
          }
        } else if (option === "no") {
        }
        setisPartnerSel(true); // Set partner selection state to true
      }
    }

  };

  { console.log("have partner select bool:", havePartnerFlag) }

  return (
    <> {loading ?
      <div className="w-full h-[100vh] flex justify-center items-center">

        <ClipLoader size={50} color={"#2D1066"} loading={loading} />
      </div >
      :
      <>
        {/* payment Status here */}
        <div className={`pt-32 w-full ${topBar ? 'block' : 'hidden'}`}>

          {/* Payment Success */}
          <div className={`w-full  h-32 px-7 items-center justify-between ${paymentSuccessView ? 'flex' : 'hidden'}`} style={{
            backgroundImage: `url(${PaymentSuccessBackgroundImage})`,
            backgroundSize: "cover",
            backgroundPosition: "center",
            backgroundRepeat: "no-repeat",
          }}>
            <h1 className="font-manrope text-2xl text-[var(--white)] font-[450] ">Your payment is successful!</h1>

            <button onClick={handleDownloadInvoice} className="mr-6">
              <img src={DownloadInvoiceButton} alt="invoice button" />
            </button>

          </div>

          {/* Payment Failure */}
          <div className={`w-full bg-[#FFC9C9] h-32 px-7 items-center justify-between ${paymentFailureView ? 'flex' : 'hidden'}`}>

            <div className="flex gap-2">
              <span><img src={RedCrossPaymentFailure} alt="red cross vector" /></span>
              <span className="text-[#812121] font-manrope text-2xl font-[450]">Your transaction has failed</span>
            </div>

            <div className="mr-6">
              <button onClick={handleRetry} className=" flex justify-center">
                {loadingRetryButton ? (<ClipLoader color="white" size={20} />)
                  : (<img src={RetryPaymentButton} alt="invoice button" />)}
              </button>



            </div>

          </div>


          {/* Added to waitlist */}
          <div className={`w-full bg-[var(--medium-purple)] h-16  items-center justify-center ${addToWaitlist ? 'flex' : 'hidden'}`}>
            <span className="text-[white] font-manrope text-2xl font-[450]">You are added to the waitlist!</span>
          </div>
          {/* Already on waitlist! */}
          <div className={`w-full bg-[#FFC9C9] h-16  items-center justify-center  ${alreadyOnWaitlist ? 'flex' : 'hidden'}`}>
            <span className="text-[#812121] font-manrope text-2xl font-[450]">You are already on waitlist!</span>
          </div>

        </div>


        {/* Rest of the current events card here */}
        <div className={` flex flex-col md:flex-row w-full pb-14 ${topBar ? 'pt-10' : 'pt-44'}`} >
          {/* Upper Part Event Details */}
          <div className="event-details flex flex-col justify-between items-center md:items-start md:w-[65%] gap-6 ">
            {/* Left Side Event card here  */}
            <div className="   flex flex-col md:flex-row justify-center md:items-start items-center w-full">
              {/* Card Div */}
              <div className="event-card-left w-[95%] rounded-3xl  md:pl-12 md:py-8  md:ml-auto flex flex-col md:flex-row md:gap-10 justify-between items-center md:items-start p-4 relative">
                {/* Left Image */}
                <div className="align-middle  md:w-[33%] md:py-6 w-full p-2">
                  {imageUrlSquare ? (
                    <img
                      className=" hidden md:block w-full h-[13rem] mx-auto rounded-3xl"
                      src={imageUrlSquare}
                      alt={eventData.EventTitle}
                    />
                  ) : ""}
                  {imageUrlWide ? (
                    <img
                      className=" md:hidden w-full h-[12rem] mt-2 rounded-3xl"
                      src={imageUrlWide}
                      alt={eventData.EventTitle}
                    />
                  ) : ""}
                </div>

                {/* Center Text */}
                <section className="written-and-logo flex md:justify-between md:py-6  w-full ">
                  <div className="flex flex-col justify-start gap-6  items-start md:w-[60%] py-5 md:py-0 text-[var(--medium-purple)] overflow-hidden ">
                    <div className="mobile-flex-view flex md:block items-center ">
                      <span className="xl:text-5xl md:text-3xl text-[1.75rem] font-semibold md:mb-10 font-[manrope] w-[80%] md:w-auto">
                        {eventData.EventTitle}
                      </span>
                      <div className=" md:hidden w-[38%] absolute left-[14.2rem] top-[12rem]">
                        {/* <img
                        className="mx-auto w-full"
                        src={leagueBadge}
                        alt="League Badge"
                      /> */}
                        <BadgeImage EventType={eventData.EventType} size={BadgeSize} />
                      </div>
                    </div>

                    <span id="scroll-container" className="font-medium md:text-lg text-base font-ClashGrotesk max-h-24 md:max-h-28 overflow-y-auto ">
                      {eventData.EventDescription}

                    </span>
                  </div>

                  {/* Right league badge image */}
                  <div className="md:w-[38%]  align-middle md:block hidden -mt-10 -ml-16">
                    {/* <img className="ml-0 md:w-full" src={leagueBadge} alt="League Badge" /> */}
                    <BadgeImage EventType={eventData.EventType} size={BadgeSize} />
                  </div>
                </section>
              </div>
            </div>

            {/* Right side payment card for mobile only view here*/}
            <div className="current-event-right-portion w-[95%] md:w-2/5 md:hidden  flex justify-center items-start ">
              {/* Card Div */}
              <div className="amount-card-right md:w-[75%] w-full   bg-[var(--white)] rounded-2xl sticky top-0 flex flex-col justify-between items-center p-3  gap-3 ">
                {/* Show count of ticket */}
                <span className="flex w-[90%] justify-between  items-center">
                  <p className="text-base font-manrope text-[var(--dark-blue)] font-[820] pt-3">
                    {eventData.EventTitle}
                  </p>
                  <span className="flex gap-2">
                    <button onClick={decrement} className={` w-6 h-6 ${eventData.IsPartnerAllowed ? '' : 'cursor-pointer'}`}>
                      <img src={minusVector} alt="minus vector" />
                    </button>
                    <p className="count-of-ticket font-manrope font-semibold text-base">{count}</p>
                    <button onClick={increment} className={`w-6 h-6 ${eventData.IsPartnerAllowed ? '' : 'cursor-pointer'}`}>
                      <img src={plusVector} alt="plus vector" />
                    </button>
                  </span>
                </span>

                {/* Partner/Team Selection Logic */}
                {eventData.IsPartnerAllowed && (
                  <div className="w-full flex flex-col justify-center items-center">
                    <hr className="text-lg border border-solid border-[var(--dull-white)] w-[93%] mt-1" />
                    <div className="w-[94%] font-manrope font-semibold mt-4">
                      <span className="flex gap-1">
                        <img src={partnerVector} alt="Partner" />
                        <p className="text-base text-[var(--medium-purple)]">
                          {eventData.MaxPlayers === 1 ? "I have a partner to play with" : "I have a team to play with"}
                        </p>
                      </span>

                      <div className="flex w-full items-center justify-between ">
                        <div
                          className={`checkbox-container flex w-[47%] rounded-lg border border-solid border-[#8868C333] justify-between text-base text-[var(--purple-blue)] mt-3 ${havePartnerOption === "yes" ? 'bg-[var(--very-light-purple)] rounded-xl px-3 py-2' : 'px-3 py-2'}`}
                        >
                          <div>Yes</div>

                          <input
                            id="havePartnerMobile"
                            name="havePartner"
                            type="checkbox"
                            className="custom-checkbox"
                            checked={havePartnerOption === "yes" && checkbox1Checked}
                            readOnly
                            onClick={() => handleSelect("yes")}
                            onChange={() => {
                              setCheckbox1Checked(true);
                              setCheckbox2Checked(false); // Uncheck checkbox 2 when checkbox 1 is selected
                            }}
                          />
                          <label htmlFor="havePartnerMobile" className="text-xs">

                          </label>
                        </div>

                        <div
                          className={`checkbox-container flex w-[47%] rounded-lg border border-solid border-[#8868C333] justify-between text-xs text-[var(--medium-purple)] mt-3 ${havePartnerOption === "no" ? 'bg-[var(--very-light-purple)] rounded-xl px-3 py-2' : 'px-3 py-2'}`}
                        >
                          <div>No</div>

                          <input
                            id="noPartnerMobile"
                            name="noPartner"
                            type="checkbox"
                            className="custom-checkbox"
                            checked={havePartnerOption === "no" && checkbox2Checked}
                            onChange={() => {
                              setCheckbox1Checked(false); // Uncheck checkbox 1 when checkbox 2 is selected
                              setCheckbox2Checked(true);
                            }}
                            readOnly
                            onClick={() => handleSelect("no")}
                          />
                          <label htmlFor="noPartnerMobile">

                          </label>
                        </div>
                      </div>


                      {/* {havePartnerOption === "no" && (
                      <div className="mt-2 pl-2 pr-4 py-3 bg-[#7070700D] font-manrope font-semibold text-sm text-[var(--gray-brown)] rounded-b-3xl">
                        Gotcha! We will help find you a playing partner — and you’ll get a refund in case it doesn’t happen.
                        Please read our <Link to="/terms" target="_blank"><u className="font-bold">Terms of Service</u></Link> to learn more.
                      </div>
                    )} */}
                    </div>
                  </div>
                )}

                {/* Horizontal Line */}
                <hr className="text-base border border-solid border-[var(--dull-white)] w-[90%]" />

                <span className="text-[var(--dark-blue)] text-2xl w-[94%] font-normal font-ClashGrotesk pb-1">
                  Total:{" "}
                  <span className="font-semibold"> ₹{eventData.Price * count}</span>
                </span>



                {/* is Registration open button */}

                <button
                  onClick={openModal}
                  className={`w-[95%]  py-3 rounded-xl font-manrope mb-2 transition-colors duration-300 ease-in-out 
                    ${eventData.isRegistrationOpen ?
                      eventData.IsPartnerAllowed ?
                        isPartnerSel ?
                          'bg-[var(--purple-blue)] text-[var(--white)]'
                          : 'bg-[--deep-plum-disabled-button] text-[var(--gray-brown)]'
                        : 'bg-[var(--purple-blue)] text-[var(--white)]'
                      : 'bg-[--deep-plum-disabled-button] text-[var(--gray-brown)]'

                    }`}
                  disabled={eventData.IsPartnerAllowed && !isPartnerSel}
                >
                  {eventData.isRegistrationOpen ? (<span>Check Out</span>) : (<span>Registration closed</span>
                  )}
                </button>




              </div>
            </div>

            {/* Event Details go here */}
            <div className="para mt-10 md:p-8 md:pr-0 w-full  ">
              <div className="flex flex-col gap-6 2xl:gap-8 w-full text-[var(--dark-blue)] ">
                {/* Event Details Heading */}
                <div className="flex flex-col md:flex-row  w-full p-4 gap-3 md:gap-6 ">
                  <div className="md:w-1/3 md:text-[2rem] text-[1.75rem] font-medium font-ClashGrotesk">
                    Event Details
                  </div>
                  <div className="md:w-2/3 flex flex-col justify-between items-start text-[var(--medium-black)] text-xl  font-manrope font-[630]">
                    <span className="pl-2 flex gap-1 py-2 md:py-1">
                      {" "}
                      <span className="mt-1"><img src={calendarVector} alt="calendar" /></span>

                      {formattedDates.startDate}
                    </span>
                    <hr className="text-base  border border-solid border-[var(--dull-white)] w-[90%] md:w-full my-2 border-opacity-40 hidden md:block" />
                    <span className="pl-2 flex gap-1 py-2 md:py-1">
                      {" "}
                      <span className="mt-1"><img src={timeVector} alt="clock" /></span>
                      {formattedDates.StartTimeStamp} - {formattedDates.EndTimeStamp}
                    </span>
                    <hr className="text-base border border-solid border-[var(--dull-white)] w-[90%] md:w-full my-2 border-opacity-40 hidden md:block" />
                    <span className="pl-2 flex gap-1 py-2 md:py-1">
                      {" "}
                      <span className="mt-1"><img src={locationVector} alt="location" /></span>
                      {" "}
                      {eventData?.venue.VenueName},{" "}
                      {eventData?.venue.VenueAddress.city},{" "}
                      {eventData?.venue.VenueAddress.state},{" "}
                      {eventData?.venue.VenueAddress.country},{" "}
                      {eventData?.venue.VenueAddress.pincode}
                    </span>
                    <hr className="text-base border border-solid border-[var(--dull-white)] w-[90%] md:w-full my-2 border-opacity-40 hidden md:block" />
                  </div>
                </div>

                {/* About one day tournaments heading */}
                <div className="flex flex-col md:flex-row gap-3 md:gap-6  p-4">
                  <div className="md:w-1/3 md:text-[2rem] text-[1.75rem]   font-medium font-ClashGrotesk">
                    About
                  </div>
                  <div className="md:w-2/3 flex flex-col justify-between items-start text-xl  font-manrope font-medium">
                    <span>{eventData.EventDescription}</span>
                  </div>
                </div>

                {/* About the sponsors */}
                {eventData?.sponsors?.length > 0 && (
                  <div className="flex flex-col md:flex-row gap-3 md:gap-6 2xl:gap-6 p-4 md:items-center">
                    <div className="md:w-1/3 text-[1.75rem] md:text-[2rem] font-medium font-ClashGrotesk">
                      Sponsors
                    </div>
                    <div className="md:w-2/3 flex justify-between items-start text-xl font-manrope font-medium">
                      <ShowSponsors eventData={eventData} />
                    </div>
                  </div>
                )}

                {/* Location Link */}
                <div className="flex flex-col md:flex-row gap-3 md:gap-6 2xl:gap-6 p-4">
                  <div className="md:w-1/3 md:text-[2rem] text-[1.75rem] font-medium font-ClashGrotesk">
                    Location Link
                  </div>
                  <div className="md:w-2/3 flex flex-col justify-between items-start text-xl  text-[var(--medium-black)] underline font-manrope font-semibold">
                    <span>
                      <a
                        className="flex gap-1 "
                        target="_blank" rel="noreferrer"
                        href={eventData.venue.MapLink}
                      >
                        {" "}
                        <img src={linkVector} alt="link vector" /> {eventData?.venue.VenueName},{" "}
                        {eventData?.venue.VenueAddress.state},{" "}
                        {eventData?.venue.VenueAddress.country},{" "}
                        {eventData?.venue.VenueAddress.pincode}
                      </a>
                    </span>
                  </div>
                </div>

                {/* Event FAQs */}
                <div className="flex flex-col md:flex-row gap-3 md:gap-6  p-4">
                  <div className="md:w-1/3 md:text-[2rem] text-[1.75rem]  font-medium font-ClashGrotesk">
                    Event FAQs
                  </div>
                  <div className="md:w-2/3 flex flex-col justify-between items-start text-base 2xl:text-xl font-manrope font-semibold ">
                    <AccordianFaq eventData={eventData} />
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* Right side payment card  here for medium and above devices view*/}
          <div className="current-event-right-portion w-[90%] md:w-[35%] hidden md:flex justify-center items-start">
            {/* Card Div */}
            <div className="amount-card-right md:w-[80%] w-full bg-[var(--white)] rounded-2xl sticky top-[7.5rem] flex flex-col justify-between items-center py-6  gap-6 md:gap-4">
              {/* Show count of ticket */}
              <span className="flex w-[95%] justify-between pl-1 pr-2 items-center gap-2">
                <p className="text-base md:text-lg font-extrabold font-manrope text-[var(--dark-blue)]">
                  {eventData.EventTitle}
                </p>
                <span className="flex gap-2">
                  <button onClick={decrement} className={`w-6 h-6 ${eventData.IsPartnerAllowed ? 'cursor-default' : 'cursor-pointer'}`}>
                    <img src={minusVector} alt="minus vector" />
                  </button>
                  <p className="count-of-ticket font-manrope font-semibold text-base">{count}</p>
                  <button onClick={increment} className={`w-6 h-6 ${eventData.IsPartnerAllowed ? 'cursor-default' : 'cursor-pointer'}`}>
                    <img src={plusVector} alt="plus vector" />
                  </button>
                </span>
              </span>

              {/* Partner/Team Selection Logic */}
              {eventData.IsPartnerAllowed && (
                <div className="w-full flex flex-col justify-center items-center">
                  <hr className="text-lg border border-solid border-[var(--dull-white)] w-[93%] mt-1" />
                  <div className="w-[94%] font-manrope font-semibold mt-4">
                    <span className="flex gap-1">
                      <img src={partnerVector} alt="Partner" />
                      <p className="text-base text-[var(--medium-purple)]">
                        {eventData.MaxPlayers === 1 ? "I have a partner to play with" : "I have a team to play with"}
                      </p>
                    </span>

                    <div
                      className={`checkbox-container flex justify-between text-base text-[var(--purple-blue)] mt-3 ${havePartnerOption === "yes" ? 'bg-[var(--very-light-purple)] rounded-xl p-3' : 'px-3 py-2'}`}
                    >
                      <div className="text-xs">Yes, I have {eventData.MaxPlayers === 1 ? "a partner" : "a team"} to register with.</div>

                      <input
                        id="havePartner"
                        name="havePartner"
                        type="checkbox"
                        className="custom-checkbox"
                        checked={havePartnerOption === "yes" && checkbox1Checked}
                        readOnly
                        onClick={() => handleSelect("yes")}
                        onChange={() => {
                          setCheckbox1Checked(true);
                          setCheckbox2Checked(false); // Uncheck checkbox 2 when checkbox 1 is selected
                        }}
                      />
                      <label htmlFor="havePartner" className="text-xs">

                      </label>
                    </div>

                    <div
                      className={`checkbox-container flex justify-between text-xs text-[var(--medium-purple)] mt-2 transition-colors duration-300 ease-in-out  ${havePartnerOption === "no" ? 'bg-[var(--very-light-purple)] rounded-xl p-3' : 'px-3 py-2'}`}
                    >
                      <div className="text-xs">No, I’ll need to be paired up with {eventData.MaxPlayers === 1 ? "a partner" : "a team"}.</div>

                      <input
                        id="noPartner"
                        name="noPartner"
                        type="checkbox"
                        className="custom-checkbox"
                        checked={havePartnerOption === "no" && checkbox2Checked}
                        onChange={() => {
                          setCheckbox1Checked(false); // Uncheck checkbox 1 when checkbox 2 is selected
                          setCheckbox2Checked(true);
                        }}
                        readOnly
                        onClick={() => handleSelect("no")}
                      />
                      <label className="flex shrink-0" htmlFor="noPartner">

                      </label>
                    </div>

                    {havePartnerOption === "no" && (
                      <div className="mt-2 pl-2 pr-4 py-3 bg-[#7070700D] font-manrope font-semibold text-sm text-[var(--gray-brown)] rounded-b-3xl ">
                        Gotcha! We will help find you a playing partner — and you’ll get a refund in case it doesn’t happen.
                        Please read our <Link to="/terms" target="_blank"><u className="font-bold">Terms of Service</u></Link> to learn more.
                      </div>
                    )}
                  </div>
                </div>
              )}

              {/* Horizontal Line */}
              <hr className="text-lg border border-solid border-[var(--dull-white)] w-[93%]" />

              {/* Total Amount */}
              <span className="text-[var(--dark-blue)] text-3xl md:text-[2rem] w-[94%] font-normal font-ClashGrotesk">
                Total: <span className="font-[520]">₹{eventData.Price * count}</span>
              </span>

              {/* Checkout Button */}



              <button
                onClick={openModal}
                className={`w-[95%]  py-3 rounded-xl font-manrope mb-2 transition-colors duration-300 ease-in-out  
                    ${eventData.isRegistrationOpen ?
                    eventData.IsPartnerAllowed ?
                      isPartnerSel ?
                        'bg-[var(--purple-blue)] text-[var(--white)]'
                        : 'bg-[--deep-plum-disabled-button] text-[var(--gray-brown)]'
                      : 'bg-[var(--purple-blue)] text-[var(--white)]'
                    : 'bg-[--deep-plum-disabled-button] text-[var(--gray-brown)]'

                  }`}
                disabled={eventData.IsPartnerAllowed && !isPartnerSel}
              >
                {eventData.isRegistrationOpen ? (<span>Check Out</span>) : (<span>Registration closed</span>
                )}
              </button>



            </div>
          </div>
        </div>
      </>
    }
      {isModalOpen ? <CheckoutModal isOpen={isModalOpen} closeModal={closeModal} count={count} eventData={eventData} addToWaitlist={handleAddToWaitlist} alreadyOnWaitlist={handleAlreadyOnWaitlist} havePartnerFlag={havePartnerFlag} /> : null}

      <MoreEvents eventData={moreEvents} sliceTill={2} />
    </>
  );
};

export default CurrentEvent;
