import React, { useEffect, useState } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import axios from 'axios';
import Header from '../Header/header';
import Footer from '../Footer/footer';
import ClipLoader from 'react-spinners/ClipLoader'; // Assuming you're using ClipLoader for loadingStatus
import { useNavigate } from 'react-router-dom';

const PaymentStatus = () => {
  const [paymentData, setPaymentData] = useState(null); // State to store payment data
  const [loadingStatus, setLoadingStatus] = useState(true); // State to handle loadingStatus
  const [errorStatus, setErrorStatus] = useState(null); // State to handle errors

  // let { payment_id } = useParams();
  const [qParams] = useSearchParams();
  const payment_id = qParams.get('payment_id');
  console.log("payment id is: ", payment_id)
  

  const navigate = useNavigate();
  useEffect(() => {
    const fetchPaymentFlow = async () => {
      if (!payment_id) {
        setErrorStatus('Payment ID is missing');
        setLoadingStatus(false);
        return;
      }

      try {
        setLoadingStatus(true);

        //Fetch payment status
        const paymentStatusResponse = await axios.get(
          `${process.env.REACT_APP_BASE_URL}/payments/status/?payment_id=${payment_id}`
        );
        const subOrderId = paymentStatusResponse.data?.SubOrderId;

        if (!subOrderId) {
          throw new Error('SubOrderId is missing in the payment status response');
        }

        // Post to /user/suborder/{sub_order_id}
        const subOrderResponse = await axios.post(
          `${process.env.REACT_APP_BASE_URL}/user/sub_order/${subOrderId}`
        );
        const OfferType = subOrderResponse.data?.OfferingType;

        //Redirect to events page if offer type is events
        if (OfferType === 'Event') {
          const eventId = subOrderResponse.data?.OfferingId;
          navigate(`/events/${eventId}/?payment_id=${payment_id}`);
        }

        if (OfferType === 'Plan'){
          navigate(`/membership/?payment_id=${payment_id}`);
        }

        // Redirect with the obtained EventId
        
      } catch (err) {
        setErrorStatus(err.message || 'An error occurred during the payment flow');
      } finally {
        setLoadingStatus(false);
      }
    };

    fetchPaymentFlow();
  }, [payment_id, navigate]);

  // If loadingStatus, show a spinner
  if (loadingStatus) {
    return (
      <div className="h-[80vh] flex justify-center items-center bg-gray-100">
        <ClipLoader size={50} color="#4A90E2" />
      </div>
    );
  }

  // If there's an errorStatus, display it
  if (errorStatus) {
    return (
      <div className="h-[80vh] flex justify-center items-center bg-gray-100">
        <div className="bg-white p-8 rounded-lg shadow-lg text-center">
          <h1 className="text-3xl font-bold text-red-500">Error</h1>
          <p className="mt-4 text-lg text-gray-700">{errorStatus}</p>
        </div>
      </div>
    );
  }

  return (
    <div>
      <Header />
      <div className="h-[80vh] flex justify-center items-center bg-gray-100">
        <div className="bg-white p-8 rounded-lg shadow-lg text-center">
          {paymentData?.TransactionStatus === 'Success' ? (
            <>
              <h1 className="text-3xl font-bold text-green-500">Payment Successful</h1>
              <p className="mt-4 text-lg text-gray-700">
                Thank you for your purchase! Your payment has been successfully processed.
              </p>
              <div className="text-6xl my-6 text-green-500">✅</div>
            </>
          ) : (
            <>
              <h1 className="text-3xl font-bold text-red-500">Payment Failed</h1>
              <p className="mt-4 text-lg text-gray-700">
                Sorry, your payment could not be processed. Please try again.
              </p>
              <div className="text-6xl my-6 text-red-500">❌</div>
            </>
          )}
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default PaymentStatus;