import React from 'react'
import './currentEvent.css'
import MoreEventCard from '../EventComponents/MoreEventCard'


const moreEvents = ({ eventData, sliceTill }) => {
  return (
    <>
      <MoreEventCard eventData={eventData} sliceTill={sliceTill} />
    </>
  )
}

export default moreEvents
