import React, { useEffect, useState } from "react";
import axios from "axios";
import { ClipLoader } from 'react-spinners';
import brownzePackageImg from "../../../Asset/Membership/MembershipPackages/bronze-membership-card.svg";
import silverPackageImg from "../../../Asset/Membership/MembershipPackages/silver-membership-card.svg";
import goldPackageImg from "../../../Asset/Membership/MembershipPackages/gold-membership-card.svg";
import tickArrow from "../../../Asset/Membership/MembershipPackages/tick-arrow.svg";
import BuyNowButton from "../../../Asset/Membership/MembershipPackages/BuyNow-Button.svg";
import PaymentSuccessBackgroundImage from "../../../Asset/Events/paymentStatus/payment-success-banner.png"
import DownloadInvoiceButton from "../../../Asset/Events/paymentStatus/Download-invoice-button.svg"
import RetryPaymentButton from "../../../Asset/Events/paymentStatus/Retry-payment-button.svg"
import RedCrossPaymentFailure from "../../../Asset/Events/paymentStatus/red-cross-payment-failure.svg"
import { useSearchParams } from "react-router-dom";
// import plans from "razorpay/dist/types/plans";

const MembershipPackages = ({ onOpenMembershipPackageModal }) => {
  const [planBronze, setPlanBronze] = useState([]); // State to store the response data
  const [planSilver, setPlanSilver] = useState([]); // State to store the response data
  const [planGold, setPlanGold] = useState([]); // State to store the response data
  const [loading, setLoading] = useState(false); // State to handle loading state
  const [error, setError] = useState(""); // State to handle errors

  // Use States for payment status view
  const [topBar, setTopBar] = useState(true);
  const [paymentSuccessView, setPaymentSuccessView] = useState(false);
  const [paymentFailureView, setPaymentFailureView] = useState(false);

  // Use States for payment status
  const [paymentData, setPaymentData] = useState(null); // State to store payment data
  const [storeSubOrderId, setStoreSubOrderId] = useState('');
  const [loadingStatus, setLoadingStatus] = useState(true); // State to handle loading
  const [errorStatus, setErrorStatus] = useState(null); // State to handle errors

  const [invoiceBtnLoading, setInvoiceBtnLoading] = useState(false);
  
    //use States for retry button
    const [loadingRetryButton, setLoadingRetryButton] = useState(false);

  // qParams to search payment id 
  const [qParams] = useSearchParams()
  const payment_id = qParams.get('payment_id');

  // Fetch Payment Status and set payment status success and failure view
  useEffect(() => {
    if (!payment_id) {
      setErrorStatus('Payment ID is missing');
      setLoadingStatus(false);
      return;
    }

    const fetchPaymentStatus = async () => {
      try {
        setLoadingStatus(true);
        const res = await axios.get(
          `${process.env.REACT_APP_BASE_URL}/payments/status/?payment_id=${payment_id}`
        ); // Include payment_id in API request


        setPaymentData(res.data); // Store the response data
        setLoadingStatus(false);
        setStoreSubOrderId(res.data.SubOrderId)

        // Payment Status conditions
        if (res.data.TransactionStatus === 'Success') {

          setPaymentSuccessView(true);
          setPaymentFailureView(false);

        } else {

          setPaymentSuccessView(false);
          setPaymentFailureView(true);

        }

      } catch (err) {
        setErrorStatus(err.message || 'An errorStatus occurred while fetching payment status');
        setLoadingStatus(false);
      }
    };

    fetchPaymentStatus();
  }, [payment_id]);


  // Fetch plans
  useEffect(() => {
    const fetchPlans = async () => {
      setLoading(true); // Start loader
      try {
        const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/plans/?page=1&page_size=10`);
        setPlanBronze(response.data[2]); // Save the response data in state
        setPlanSilver(response.data[1]); // Save the response data in state
        setPlanGold(response.data[0]); // Save the response data in state

        console.log("Consoling responses one by one")
        console.log(response.data[0])
        console.log(response.data[1])
        console.log(response.data[2])
      } catch (err) {
        console.error(err);
        setError("Failed to fetch plans. Please try again later.");
      } finally {
        setLoading(false); // Stop loader
      }
    };

    fetchPlans(); // Call the function
  }, []);


  // Handle download invoice
  const handleDownloadInvoice = async (e) => {
    e.preventDefault(); // Prevent the default button click behavior
    setInvoiceBtnLoading(true)
    try {
      if (!payment_id) {
        console.error('Payment ID is not available');
        return;
      }

      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/payments/${payment_id}/invoice`,
        {
          params: { payment_id }, // Send the stored payment_id as a query parameter
          responseType: 'blob', // Expect a file (Blob) in the response
        }
      );

      // Create a URL for the Blob and trigger the download
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `NG-${payment_id}.pdf`); // Set the desired file name
      document.body.appendChild(link);
      link.click();
      link.remove(); // Clean up the link element
    } catch (error) {
      console.error('Error downloading the invoice:', error);
      // alert('Failed to download the invoice. Please try again.');

    }
    setInvoiceBtnLoading(false)
  };

  // Handle payment retry
  const handleRetry = async (e) => {
    e.preventDefault();

    setLoadingRetryButton(true);

    try {
      const checkoutResponse = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/checkout/?sub_order_id=${storeSubOrderId}`, // URL
        {}, // Data (empty object since no body is being sent)
        {
          headers: {
            "Content-Type": "application/json",
            accept: "application/json",
          },
        } // Config (e.g., headers)
      );

      const { RazorpayOrderId, amount } = checkoutResponse.data;
      handleRazorpayPayment(RazorpayOrderId, amount);
    } catch (error) {
      console.error("Error during checkout:", error);
      // Optional: Handle specific error cases
    } finally {
      setLoadingRetryButton(false);
    }
  }

  // Handle Razorpay checkout
  const handleRazorpayPayment = async (RazorpayOrderId, amount) => {
    amount = amount * 100;
    //Dynamically create a form to submit to Razorpay Hosted Checkout
    const form = document.createElement("form");
    form.setAttribute("method", "POST");
    form.setAttribute(
      "action",
      "https://api.razorpay.com/v1/checkout/embedded"
    );
    form.setAttribute("target", "_blank");

    //Define form fields dynamically
    const fields = [
      { name: "key_id", value: "rzp_test_w402YRQwCZ0zlG" }, // Your Razorpay key ID
      { name: "order_id", value: RazorpayOrderId }, // The order ID returned from backend
      { name: "amount", value: amount * 100 }, // Amount in paise (multiply INR by 100)
      { name: "currency", value: "INR" },

      { name: "name", value: "Net Gala" },
      { name: "description", value: "Please complete your payment to proceed." },
      { name: "image", value: `${process.env.REACT_APP_HOSTED_URL}/logo.png` }, // Company logo or product image
      // { name: "image", value: `http:/localhost:3000/logo.png` }, // Company logo or product image
      // { name: "image", value: `https://ibb.co/wM7K7Kc` }, // Company logo or product image
      {
        name: "callback_url",
        value: `${process.env.REACT_APP_BASE_URL}/payment/callback/`,
      },
      {
        name: "cancel_url",
        value: `${process.env.REACT_APP_BASE_URL}/payment/cancel`,
      },
      // { name: "prefill[name]", value: "name" }, // Optional pre-fill for name
      // { name: "prefill[contact]", value: "" }, // Optional pre-fill for contact
      // { name: "prefill[email]", value: "email" }, // Optional pre-fill for email
    ];

    //Append fields to the form
    fields.forEach(({ name, value }) => {
      const input = document.createElement("input");
      input.setAttribute("type", "hidden");
      input.setAttribute("name", name);
      input.setAttribute("value", value);
      form.appendChild(input);
    });

    // Append form to the body and submit it automatically
    document.body.appendChild(form);
    form.submit();



  };


  const handleOpenModalBronze = () => {
    const data = { PlanId: planBronze.PlanId, PlanTier: planBronze.PlanTier, Price: planBronze.Price }; // Example data
    onOpenMembershipPackageModal(data); // Send data to the parent
  };
  const handleOpenModalSilver = () => {
    const data = { PlanId: planSilver.PlanId, PlanTier: planSilver.PlanTier, Price: planSilver.Price }; // Example data
    onOpenMembershipPackageModal(data); // Send data to the parent
  };
  const handleOpenModalGold = () => {
    const data = { PlanId: planGold.PlanId, PlanTier: planGold.PlanTier, Price: planGold.Price }; // Example data
    onOpenMembershipPackageModal(data); // Send data to the parent
  };

  return (
    <div>

      {/* payment Status here */}
      <div className={`pt-32 w-full ${topBar ? 'block' : 'hidden'}`}>

        {/* Payment Success */}
        <div className={`w-[full]  h-32 px-7 items-center justify-between ${paymentSuccessView ? 'flex' : 'hidden'}`} style={{
          backgroundImage: `url(${PaymentSuccessBackgroundImage})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
        }}>
          <h1 className="font-manrope text-2xl text-[var(--white)] font-[450] ">Your payment is successful!</h1>

          <button onClick={handleDownloadInvoice} className="mr-6">
            <img src={DownloadInvoiceButton} alt="invoice button" />
          </button>

        </div>

        {/* Payment Failure */}
        <div className={`w-full bg-[#FFC9C9] h-32 px-7 items-center justify-between ${paymentFailureView ? 'flex' : 'hidden'}`}>

          <div className="flex gap-2">
            <span><img src={RedCrossPaymentFailure} alt="red cross vector" /></span>
            <span className="text-[#812121] font-manrope text-2xl font-[450]">Your transaction has failed</span>
          </div>

          <div className="mr-6">
            <button onClick={handleRetry} className=" flex justify-center">
              {loadingRetryButton ? (<ClipLoader color="white" size={20} />)
                : (<img src={RetryPaymentButton} alt="invoice button" />)}
            </button>

          </div>

        </div>

      </div>


      <div id="membership-heading" className="pt-14 flex justify-center">
        <div className="font-ClashGrotesk text-[var(--dark-blue)] font-medium flex flex-col items-center">
          <h1 className="text-[2.5rem] md:text-[4.5rem]">Membership Tiers</h1>
          <p className="text-[1.75rem] md:text-[2.125rem]">Choose Your Adventure!</p>
        </div>
      </div>

      <div id="membership-packages" className="mt-12">

        {/* Show loader while loading */}
        {loading ? (
          <div className="flex justify-center items-center h-64">
            <ClipLoader size={50} color={"#2D1066"} loading={loading} />
          </div>
        ) : error ? (
          <p className="text-red-500">{error} Sorry! Could not load packages.</p>
        ) : (
          <>


            {/* For medium devices view here */}
            <div className="md:flex hidden justify-center w-full md:gap-2">
              {/* Bronze Package */}
              <div
                className="w-full"
                style={{
                  backgroundImage: `url(${brownzePackageImg})`,
                  backgroundSize: "contain",
                  backgroundPosition: "center",
                  backgroundRepeat: "no-repeat",
                }}
              >
                <div
                  id="bronze-content"
                  className="flex flex-col gap-3 mt-16 mb-40 text-[var(--dark-blue)] lg:ml-4 xl:ml-10 "
                >
                  <h1 className="text-[2rem] font-ClashGrotesk font-medium">{planBronze.PlanTier}</h1>
                  <p className="font-manrope font-[520] w-5/6 text-xl">
                    Perfect for those dipping their toes into a more active lifestyle.
                  </p>
                  <p className="font-ClashGrotesk font-medium text-[3.5rem]">₹{planBronze.Price}</p>
                  <div className="flex gap-2 items-center w-72 font-manrope mb-2">
                    <span className="flex-shrink-0">
                      <img src={tickArrow} alt="tick" />
                    </span>
                    <span className="font-bold text-xl">{planBronze.Credits} Credits</span>
                    <span className="font-semibold text-base text-[var(--light-purple-membership-package-card)]">
                      at ₹{(planBronze.Price/planBronze.Credits).toFixed(2)}/credit
                    </span>
                  </div>
                  <div className="flex gap-2 items-start justify-start w-72 font-manrope">
                    <span className="flex-shrink-0">
                      <img src={tickArrow} alt="tick" />
                    </span>
                    <span className="font-bold text-xl">50%</span>
                    <span className="font-semibold text-base text-[var(--light-purple-membership-package-card)]">
                      off non-member rates on events and play-pass!
                    </span>
                  </div>
                  <button onClick={handleOpenModalBronze} className="mt-4">
                    <img src={BuyNowButton} alt="Buy Button" />
                  </button>
                </div>
              </div>

              {/* Silver Package */}
              <div
                className="w-full"
                style={{
                  backgroundImage: `url(${silverPackageImg})`,
                  backgroundSize: "contain",
                  backgroundPosition: "center",
                  backgroundRepeat: "no-repeat",
                }}
              >
                <div
                  id="silver-content"
                  className="flex flex-col gap-3 mt-16 mb-40 text-[var(--dark-blue)] lg:ml-4 xl:ml-10 "
                >
                  <h1 className="text-[2rem] font-ClashGrotesk font-medium">{planSilver.PlanTier}</h1>
                  <p className="font-manrope font-[520] w-5/6 text-xl">
                    Perfect for those dipping their toes into a more active lifestyle.
                  </p>
                  <p className="font-ClashGrotesk font-medium text-[3.5rem]">₹{planSilver.Price}</p>
                  <div className="flex gap-2 items-center w-72 font-manrope mb-2">
                    <span className="flex-shrink-0">
                      <img src={tickArrow} alt="tick" />
                    </span>
                    <span className="font-bold text-xl">{planSilver.Credits} Credits</span>
                    <span className="font-semibold text-base text-[var(--light-purple-membership-package-card)]">
                      at ₹{(planSilver.Price/planSilver.Credits).toFixed(2)}/credit
                    </span>
                  </div>
                  <div className="flex gap-2 items-start justify-start w-72 font-manrope">
                    <span className="flex-shrink-0">
                      <img src={tickArrow} alt="tick" />
                    </span>
                    <span className="font-bold text-xl">58%</span>
                    <span className="font-semibold text-base text-[var(--light-purple-membership-package-card)]">
                      off non-member rates on events and play-pass!
                    </span>
                  </div>
                  <button onClick={handleOpenModalSilver} className="mt-4">
                    <img src={BuyNowButton} alt="Buy Button" />
                  </button>
                </div>
              </div>

              {/* Gold Package */}
              <div
                className="w-full"
                style={{
                  backgroundImage: `url(${goldPackageImg})`,
                  backgroundSize: "contain",
                  backgroundPosition: "center",
                  backgroundRepeat: "no-repeat",
                }}
              >
                <div
                  id="gold-content"
                  className="flex flex-col gap-3 mt-16 mb-40 text-[var(--dark-blue)] lg:ml-4 xl:ml-10 "
                >
                  <h1 className="text-[2rem] font-ClashGrotesk font-medium">{planGold.PlanTier}</h1>
                  <p className="font-manrope font-[520] w-5/6 text-xl">
                    Perfect for those dipping their toes into a more active lifestyle.
                  </p>
                  <p className="font-ClashGrotesk font-medium text-[3.5rem]">₹{planGold.Price}</p>
                  <div className="flex gap-2 items-center w-72 font-manrope mb-2">
                    <span className="flex-shrink-0">
                      <img src={tickArrow} alt="tick" />
                    </span>
                    <span className="font-bold text-xl">{planGold.Credits} Credits</span>
                    <span className="font-semibold text-base text-[var(--light-purple-membership-package-card)]">
                      at ₹{(planGold.Price/planGold.Credits).toFixed(2)}/credit
                    </span>
                  </div>
                  <div className="flex gap-2 items-start justify-start w-72 font-manrope">
                    <span className="flex-shrink-0">
                      <img src={tickArrow} alt="tick" />
                    </span>
                    <span className="font-bold text-xl">70%</span>
                    <span className="font-semibold text-base text-[var(--light-purple-membership-package-card)]">
                      off non-member rates on events and play-pass!
                    </span>
                  </div>
                  <button onClick={handleOpenModalGold} className="mt-4">
                    <img src={BuyNowButton} alt="Buy Button" />
                  </button>
                </div>
              </div>
            </div>



            {/* For mobile view here */}

            <div className="home-about-us-cards md:hidden carousel  w-[95vw]  md:mb-16 flex ">
              <div className="carousel-item  w-[80vw] md:w-[60vw] pl-2 ">

                {/* Bronze Package */}
                <div
                  className="w-full pl-3 flex items-center"
                  style={{
                    backgroundImage: `url(${brownzePackageImg})`,
                    backgroundSize: "contain",
                    backgroundPosition: "center",
                    backgroundRepeat: "no-repeat",
                  }}
                >
                  <div
                    id="bronze-content"
                    className="flex flex-col gap-3 mt-16 mb-40 text-[var(--dark-blue)] lg:ml-4 xl:ml-10 "
                  >
                    <h1 className="text-[1.70rem] font-ClashGrotesk font-medium">{planBronze.PlanTier}</h1>
                    <p className="font-manrope font-[520] w-5/6 text-[0.95rem]">
                      Perfect for those dipping their toes into a more active lifestyle.
                    </p>
                    <p className="font-ClashGrotesk font-medium text-[2.2rem]">₹{planBronze.Price}</p>
                    <div className="flex gap-2 items-center w-72 font-manrope mb-2">
                      <span className="flex-shrink-0">
                        <img src={tickArrow} alt="tick" />
                      </span>
                      <span className="font-bold text-[1.2rem]">{planBronze.Credits} Credits</span>
                      <span className="font-semibold text-[0.9rem] text-[var(--light-purple-membership-package-card)]">
                        at ₹{(planBronze.Price/planBronze.Credits).toFixed(2)}/credit
                      </span>
                    </div>
                    <div className="flex gap-2 items-start justify-start w-72 font-manrope">
                      <span className="flex-shrink-0">
                        <img src={tickArrow} alt="tick" />
                      </span>
                      <span className="font-bold text-[1.2rem]">50%</span>
                      <span className="font-semibold text-[0.9rem] text-[var(--light-purple-membership-package-card)]">
                        off non-member rates on events and play-pass!
                      </span>
                    </div>
                    <button onClick={handleOpenModalBronze} className="mt-4 w-28">
                      <img src={BuyNowButton} alt="Buy Button" />
                    </button>
                  </div>
                </div>

              </div>


              <div className="carousel-item   w-[80vw] md:w-[60vw] px-3">

                {/* Silver Package */}
                <div
                  className="w-full pl-3 flex items-center"
                  style={{
                    backgroundImage: `url(${silverPackageImg})`,
                    backgroundSize: "contain",
                    backgroundPosition: "center",
                    backgroundRepeat: "no-repeat",
                  }}
                >
                  <div
                    id="bronze-content"
                    className="flex flex-col gap-3 mt-16 mb-40 text-[var(--dark-blue)] lg:ml-4 xl:ml-10 "
                  >
                    <h1 className="text-[1.70rem] font-ClashGrotesk font-medium">{planSilver.PlanTier}</h1>
                    <p className="font-manrope font-[520] w-5/6 text-[0.95rem]">
                      Ideal for regulars aiming for the perfect balance of fitness and fun.
                    </p>
                    <p className="font-ClashGrotesk font-medium text-[2.2rem]">₹{planSilver.Price}</p>
                    <div className="flex gap-2 items-center w-72 font-manrope mb-2">
                      <span className="flex-shrink-0">
                        <img src={tickArrow} alt="tick" />
                      </span>
                      <span className="font-bold text-[1.2rem]">{planSilver.Credits} Credits</span>
                      <span className="font-semibold text-[0.9rem] text-[var(--light-purple-membership-package-card)]">
                        at ₹{(planSilver.Price/planSilver.Credits).toFixed(2)}/credit
                      </span>
                    </div>
                    <div className="flex gap-2 items-start justify-start w-72 font-manrope">
                      <span className="flex-shrink-0">
                        <img src={tickArrow} alt="tick" />
                      </span>
                      <span className="font-bold text-[1.2rem]">58%</span>
                      <span className="font-semibold text-[0.9rem] text-[var(--light-purple-membership-package-card)]">
                        off non-member rates on events and play-pass!
                      </span>
                    </div>
                    <button onClick={handleOpenModalSilver} className="mt-4 w-28">
                      <img src={BuyNowButton} alt="Buy Button" />
                    </button>
                  </div>
                </div>

              </div>


              <div className="carousel-item   w-[80vw] md:w-[60vw] pr-3">

                {/* Gold Package */}
                <div
                  className="w-full pl-3 flex items-center"
                  style={{
                    backgroundImage: `url(${goldPackageImg})`,
                    backgroundSize: "contain",
                    backgroundPosition: "center",
                    backgroundRepeat: "no-repeat",
                  }}
                >
                  <div
                    id="bronze-content"
                    className="flex flex-col gap-3 mt-16 mb-40 text-[var(--dark-blue)] lg:ml-4 xl:ml-10 "
                  >
                    <h1 className="text-[1.70rem] font-ClashGrotesk font-medium">{planGold.PlanTier}</h1>
                    <p className="font-manrope font-[520] w-5/6 text-[0.95rem]">
                      Crafted for the elite, the dedicated, the game-changers.
                    </p>
                    <p className="font-ClashGrotesk font-medium text-[2.2rem]">₹{planGold.Price}</p>
                    <div className="flex gap-2 items-center w-72 font-manrope mb-2">
                      <span className="flex-shrink-0">
                        <img src={tickArrow} alt="tick" />
                      </span>
                      <span className="font-bold text-[1.2rem]">{planGold.Credits} Credits</span>
                      <span className="font-semibold text-[0.9rem] text-[var(--light-purple-membership-package-card)]">
                        at ₹{(planGold.Price/planGold.Credits).toFixed(2)}/credit
                      </span>
                    </div>
                    <div className="flex gap-2 items-start justify-start w-72 font-manrope">
                      <span className="flex-shrink-0">
                        <img src={tickArrow} alt="tick" />
                      </span>
                      <span className="font-bold text-[1.2rem]">70%</span>
                      <span className="font-semibold text-[0.9rem] text-[var(--light-purple-membership-package-card)]">
                        off non-member rates on events and play-pass!
                      </span>
                    </div>
                    <button onClick={handleOpenModalGold} className="mt-4 w-28">
                      <img src={BuyNowButton} alt="Buy Button" />
                    </button>
                  </div>
                </div>

              </div>
            </div>
          </>

        )}

      </div>
    </div>
  );
};

export default MembershipPackages;