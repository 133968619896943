import React from 'react';

const EventsFilterModal = ({ isOpen, onClose, selectedCities, selectedSports, selectedEvents, handleToggle, allRegions, allSportsType, allEventType }) => {
    if (!isOpen) return null; // Don't render the modal if it's not open

    return (
        <div className="fixed inset-0 flex items-end justify-center bg-gray-800 bg-opacity-50 z-50">
            <div className="relative bg-[var(--background-light-gray)] p-6 rounded-t-xl shadow-lg w-full max-h-[90%]">
                {/* Header */}
                <div className="flex justify-between items-center mb-6">
                    <h2 className="text-[1.75rem] font-ClashGrotesk text-[var(--dark-blue)]  font-medium">Filters</h2>
                    <button onClick={onClose} className="absolute top-8 right-7 md:top-3 md:right-4 text-xl text-[var(--purple-blue)]">&#x2715;</button>
                </div>

                 {/* Cities Filter */}
                 <div className="mb-6">
                    <h3 className="font-manrope font-[630] text-xs text-[var(--eventListing-filterbtn-heading-gray)] mb-3">City</h3>
                    <div className="flex flex-wrap gap-3">
                        {allRegions.map((city) => (
                            <button
                                key={city.id}
                                onClick={() => handleToggle('city', city.id, city.name)}
                                className={`px-4 py-2 rounded-md text-base flex items-center gap-2 font-manrope font-[630] border-[0.5px] text-[var(--filter-button-text-purple)] border-[#8F54FF] ${
                                    selectedCities.some(cityObj => cityObj.id === city.id) 
                                        ? 'bg-[var(--filter-button-background-purple)] ' 
                                        : 'bg-[var(--eventListing-filterbtn-bg-white)]'
                                }`}
                            >
                                {city.name}
                                {selectedCities.some(cityObj => cityObj.id === city.id) && (
                                    <span className="border border-[#8F54FF] w-[1.016rem] h-[1.016rem] rounded-full p-1 flex items-center justify-center text-[#5C1BDC87] text-xs font-bold">
                                        &#10005;
                                    </span>
                                )}
                            </button>
                        ))}
                    </div>
                </div>

                {/* Sports Filter */}
                <div className="mb-6">
                    <h3 className="font-manrope font-[630] text-xs text-[var(--eventListing-filterbtn-heading-gray)] mb-3">Sport</h3>
                    <div className="flex flex-wrap gap-3">
                        {allSportsType.map((sport) => (
                            <button
                                key={sport.id}
                                onClick={() => handleToggle('sport', sport.id, sport.name)}
                                className={`px-4 py-2 rounded-md text-base flex items-center gap-2 font-manrope font-[630] border-[0.5px] text-[var(--filter-button-text-purple)] border-[#8F54FF] ${
                                    selectedSports.some(sportObj => sportObj.id === sport.id)
                                        ? 'bg-[var(--filter-button-background-purple)] ' 
                                        : 'bg-[var(--eventListing-filterbtn-bg-white)]'
                                }`}
                            >
                                {sport.name}
                                {selectedSports.some(sportObj => sportObj.id === sport.id) && (
                                    <span className="border border-[#8F54FF] w-[1.016rem] h-[1.016rem]  rounded-full p-1 flex items-center justify-center text-[#5C1BDC87] text-xs font-bold">
                                        &#10005;
                                    </span>
                                )}
                            </button>
                        ))}
                    </div>
                </div>

                {/* Events Filter */}
                <div className="mb-16">
                    <h3 className="font-manrope font-[630] text-xs text-[var(--eventListing-filterbtn-heading-gray)] mb-3">Event</h3>
                    <div className="flex flex-wrap gap-3">
                        {allEventType.map((event) => (
                            <button
                                key={event.id}
                                onClick={() => handleToggle('event', event.id, event.name)}
                                className={`px-4 py-2 rounded-md text-base flex items-center gap-2 font-manrope font-[630] border-[0.5px] text-[var(--filter-button-text-purple)] border-[#8F54FF] ${
                                    selectedEvents.some(eventObj => eventObj.id === event.id) 
                                        ? 'bg-[var(--filter-button-background-purple)]' 
                                        : 'bg-[var(--eventListing-filterbtn-bg-white)] '
                                }`}
                            >
                                {event.name}
                                {selectedEvents.some(eventObj => eventObj.id === event.id) && (
                                    <span className="border border-[#8F54FF] w-[1.016rem] h-[1.016rem] rounded-full p-1 flex items-center justify-center text-[#5C1BDC87] text-xs font-bold">
                                        &#10005;
                                    </span>
                                )}
                            </button>
                        ))}
                    </div>
                </div>


                {/* Show Results Button */}
                <button
                    className="w-full py-4 bg-[var(--purple-blue)] text-[var(--white)] rounded-lg font-medium"
                    onClick={onClose}
                >
                    Show Results
                </button>
            </div>
        </div>
    );
};

export default EventsFilterModal;
