import React from 'react'
import footballImage from "../../../Asset/About/aboutUs-football-image.png"
import footballImageMobile from "../../../Asset/About/aboutUs-football-image-mobile.png"

const MidContent = () => {
    return (
        <>
            <div className='mt-20 bg-[var(--white)] py-20 p-4 text-[var(--dark-blue)]'>
                {/* Upper Text Here */}
                <div className='text-[1.75rem] md:text-3xl lg:text-4xl xl:text-5xl font-ClashGrotesk font-medium  md:text-justify mb-4 md:mb-12 md:pl-10'>
                    <p>We take our fun very seriously. <br className='hidden md:block' />This will be a community like no other!</p>
                </div>

                {/* Image and Description Here */}
                <div className='flex flex-col md:flex-row p-2 text-justify gap-8 md:gap-4 w-full'>
                    {/* Image comes here */}
                    <div className='md:w-1/2  flex justify-center items-center'>
                        <img className='hidden md:block ' src={footballImage} alt="Playing Football" />
                        <img className='md:hidden w-full' src={footballImageMobile} alt="Playing Football" />
                    </div>

                    {/* Description Comes Here */}
                    <div className='md:w-1/2 flex flex-col justify-center items-start gap-4 md:gap-8 font-manrope font-medium text-base md:text-sm lg:text-base xl:text-xl md:pr-12 xl:pr-20 text-[var(--dark-blue)]'>
                        <p>It all started with an itch to do something big in sports, and after talking with a friend who shared my love for football, I decided it was time to stop just talking about our passion and do something about it. So, fueled by caffeine and a mix of genius and ridiculousness, I texted a few friends, told them they were now captains of football teams, and gave them nothing more than a date and location—without revealing what they were in for. </p>

                        <p>On the big day, torrential rain threatened to cancel everything, but to my surprise, people showed up, ready to play, soaked to the bone. We played through the rain, mud flying everywhere, and ended the night with cold beers and the realization that we weren’t just organizing a game—we were building a community.</p>

                        <p>That began as a rainy-day gamble has since evolved into a series of sports events where competition meets connection, and every match feels like a victory lap, no matter the score.</p>
                    </div>
                </div>
            </div>
        </>
    )
}

export default MidContent
